
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { MessagingServiceConfigurationController } from "@/app/ui/controllers/MessagingServiceConfigurationController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";

@Options({
  components: {
    Title
  }
})
export default class MessagingServiceConfigurationFormMessageInformationSection2 extends Vue {
  get isFormTypeEdit() {
    return this.$route.meta.formType === "edit";
  }
  get form() {
    return MessagingServiceConfigurationController.messageServiceForm;
  }
  get showSelectPOS() {
    return this.form.appliedTo.includes("pos");
  }
  get showSelectClient() {
    return this.form.appliedTo.includes("client");
  }

  // origin city
  fetchCity(search: string) {
    LocationController.getCityList({
      search: search,
      status: "active",
      page: 1,
      limit: 10
    });
  }
  filterCity = debounce((search: string) => {
    if (search.length > 2 || !search) this.fetchCity(search);
  }, 250);
  get cityExcludeOptions() {
    return LocationController.cityData.cityData.map(e => ({
      id: e.id,
      name: `${e.code} - ${e.name}`,
      value: `${e.code}`
    }));
  }
  get cityOptions() {
    return [
      {
        name: "All Origin City",
        value: "All"
      },
      ...this.cityExcludeOptions
    ];
  }
  get isLoadingCity() {
    return LocationController.isLoading;
  }
  get isChooseAllCityOrigin() {
    const allItemIndex = this.form.originCities.findIndex((item: any) => {
      return item.value === "All";
    });
    return allItemIndex > -1;
  }
  onChangeOriginCity() {
    this.form.originCitiesExclude = [];
  }

  // pos
  listPOS: any = [];
  isLoadingPOS = false;
  get isChooseAllPOS() {
    const allItemIndex = this.form.posPartners.findIndex((item: any) => {
      return item.value === "All";
    });
    return allItemIndex > -1;
  }
  async fetchPOS(search: string) {
    this.isLoadingPOS = true;
    this.listPOS = await PartnerController.fetchPartnerApi({
      page: 1,
      limit: 10,
      search: search,
      type: "pos",
      cityCode:
        this.isChooseAllCityOrigin || this.form.originCities.length === 0
          ? "ALL"
          : this.form.originCities
              .map((city: any) => {
                return city.value;
              })
              .join(","),
      cityCodeExclude: this.form.originCitiesExclude
        .map((city: any) => {
          return city.value;
        })
        .join(",")
    });
    this.isLoadingPOS = false;
  }
  filterPOS = debounce(async (search: string) => {
    await this.fetchPOS(search);
  }, 250);
  get posOptions() {
    if (this.posExcludeOptions.length === 0) {
      return [];
    }
    return [
      {
        name: "All POS",
        value: "All"
      },
      ...this.posExcludeOptions
    ];
  }
  get posExcludeOptions() {
    return this.listPOS.map((pos: any) => {
      return {
        name: pos.name,
        value: pos.id
      };
    });
  }
  onChangePOS() {
    this.form.posPartnersExclude = [];
  }

  // client branch
  isLoadingClientBranch = false;
  listClientBranch: any = [];
  get isChooseAllClientBranch() {
    const allItemIndex = this.form.clientBranches.findIndex((item: any) => {
      return item.value === "All";
    });
    return allItemIndex > -1;
  }
  get clientBranchExcludeOptions() {
    return this.listClientBranch.map((clientBranch: any) => {
      return {
        name: clientBranch.clientCompanyName,
        value: clientBranch.clientId
      };
    });
  }
  get clientBranchOptions() {
    if (this.clientBranchExcludeOptions.length === 0) {
      return [];
    }
    return [
      {
        name: "All Client Cabang",
        value: "All"
      },
      ...this.clientBranchExcludeOptions
    ];
  }

  async fetchClient(clientType: string, search: string) {
    return await ClientManagementController.fetchClientManagementApi(
      {
        page: 1,
        limit: 10,
        search,
        cityCode:
          this.isChooseAllCityOrigin || this.form.originCities.length === 0
            ? "ALL"
            : this.form.originCities
                .map((city: any) => {
                  return city.value;
                })
                .join(","),
        cityCodeExclude: this.form.originCitiesExclude
          .map((city: any) => {
            return city.value;
          })
          .join(","),
        type: clientType
      }
    );
  }
  async fetchClientBranch(search: string) {
    this.isLoadingClientBranch = true;
    this.listClientBranch = await this.fetchClient("branch", search)
    this.isLoadingClientBranch = false;
  }
  filterClientBranch = debounce(async (search: string) => {
    if (search.length < 3 && search.length > 0) {
      return;
    }
    await this.fetchClientBranch(search);
  }, 250);
  onChangeClientBranch() {
    this.form.clientBranchesExclude = [];
    this.form.clientParent = [];
    this.form.clientParentExclude = [];
  }

  //client parent
  isLoadingClientParent = false;
  listClientParent: any = [];
  get clientParentExcludeOptions() {
    return this.listClientParent.map((clientParent: any) => {
      return {
        name: clientParent.clientCompanyName,
        value: clientParent.clientId
      };
    });
  }
  get clientParentOptions() {
    if (this.clientParentExcludeOptions.length === 0) {
      return [];
    }
    return [
      ...this.clientParentExcludeOptions
    ];
  }
async fetchClientParent(search: string) {
    this.isLoadingClientParent = true;
    this.listClientParent = await this.fetchClient("parent", search);
    this.isLoadingClientParent = false;
  }
  filterClientParent = debounce(async (search: string) => {
    if (search.length < 3 && search.length > 0) {
      return;
    }
    await this.fetchClientParent(search);
  }, 250);
  onChangeClientParent() {
    this.form.clientParentExclude = [];
  }

  get invalidStartDate() {
    return (
      this.form.startDate.getTime() > this.form.endDate.getTime() ||
      this.form.startDate.getTime() === this.form.endDate.getTime()
    );
  }
  get invalidEndDate() {
    return (
      this.form.endDate.getTime() < this.form.startDate.getTime() ||
      this.form.endDate.getTime() === this.form.startDate.getTime()
    );
  }
  get minimumDateForEndDate() {
    const date =
      this.form.startDate.getTime() >= new Date().getTime()
        ? this.form.startDate
        : new Date();
    return new Date(new Date().setDate(date.getDate() + 1));
  }
}
